const FooterBottom = () => {
  return (
    <div className="footer-bottom">
      <p>
        Copyright &copy; 2022 Imperial Engineering Associates | Designed &
        Developed by <a href="https://www.uttammishra.com" target="_blank">Uttam Mishra</a>
      </p>
    </div>
  );
};
export default FooterBottom;
